import { api } from "@/config"
import { format as dateFormat } from "date-fns"
import { get, isEmpty, isUndefined, map, transform } from "lodash-es"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { handleFailedResponse } from "@/helpers/common"

const mappedDateRange = ([start, end]) => {
  if (isUndefined(start) || isUndefined(end)) {
    return null
  } else {
    return {
      start: dateFormat(start, DATE_FNS_DATE_FORMAT),
      end: dateFormat(end, DATE_FNS_DATE_FORMAT)
    }
  }
}

const mapFilters = filters => {
  return {
    date_range: mappedDateRange(filters.dateRange),
    period_inclusion_type: get(filters.periodInclusion, "id"),
    search_value: filters.searchValue
  }
}

const transformItems = items => {
  return map(items, item => ({ ...item, periods: transformPeriods(item.periods) }))
}

const transformPeriods = periods => {
  return map(periods, period => ({ ...period, start: new Date(period.start), end: new Date(period.end) }))
}

const preparePricePeriodParams = ({ itemsAdded, itemsDeleted, itemsUpdated }) => {
  return transform(
    {
      update: itemsUpdated,
      delete: itemsDeleted,
      create: itemsAdded
    },

    (result, items, key) => {
      if (items.length) {
        result[key] = map(items, ({ id, name, periods }) => ({
          id,
          name,
          periods: preparePeriodParams(periods)
        }))
      }
    },
    {}
  )
}

const preparePeriodParams = periods => {
  return map(periods, period => ({
    ...period,
    start: dateFormat(period.start, DATE_FNS_DATE_FORMAT),
    end: dateFormat(period.end, DATE_FNS_DATE_FORMAT)
  }))
}

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { filters, pagination, sorting } }, customParams = {}) => {
    const params = {
      filters: mapFilters(filters),
      pagination,
      sorting: normalizeSortingParams(sorting),
      ...customParams
    }
    const response = await api.get(baseURI, { params })
    const { items, meta } = response.data.data
    commit("SET_ITEMS", transformItems(items))
    commit("SET_PAGINATION_DATA", meta.pagination)
  },

  UPDATE_ITEMS: async (
    { state: { itemsAdded, itemsDeleted, filters, pagination, sorting }, commit, getters: { itemsUpdated } },
    customParams = {}
  ) => {
    const price_periods = preparePricePeriodParams({ itemsAdded, itemsUpdated, itemsDeleted })
    try {
      if (isEmpty(price_periods)) return Promise.resolve()
      const params = { filters, pagination, sorting, ...customParams }

      const response = await api.put(baseURI, { price_periods, ...params })
      const { items, meta } = response.data.data

      commit("SET_ITEMS", items)
      commit("SET_PAGINATION_DATA", meta.pagination)
      commit("RESET_ITEMS_DELETED")
      commit("RESET_ITEMS_ADDED")
    } catch (error) {
      handleFailedResponse(error)
    }
  }
})
