import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"

const BASE_URI = "/plan_management/sources_rakuten_price_periods"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: {
    searchValue: "",
    periodInclusion: undefined,
    dateRange: []
  },
  withPagination: true,
  withSorting: true
})

store.mergeActions(extractActions({ baseURI: BASE_URI }), withLoading)

export default store
